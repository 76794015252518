// Import the functions you need from the SDKs you need
import axios from "axios";
import { initializeApp } from "firebase/app";

import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  UserCredential,
} from "firebase/auth";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";

// Initialize Firebase
export const app = initializeApp({
  apiKey: "AIzaSyCWcg0QggyHX7TJ_VNE4PQlS0kJU36DWHw",
  authDomain: "hyfe-app.firebaseapp.com",
  databaseURL: "https://hyfe-app.firebaseio.com",
  projectId: "hyfe-app",
  storageBucket: "hyfe-app.appspot.com",
  messagingSenderId: "209765541901",
  appId: "1:209765541901:web:35332c26913324ab740d44",
  measurementId: "G-SH2145HZ77",
});

export const signInWithEmailAndPassword = (email: string, password: string) => {
  const auth = getAuth(app);
  return firebaseSignInWithEmailAndPassword(auth, email, password);
};

export const signInWithProvider = (provider: string) => {
  const auth = getAuth();
  var pvdr;
  switch (provider) {
    case "google":
      pvdr = new GoogleAuthProvider();
      break;
    case "twitter":
      pvdr = new TwitterAuthProvider();
      break;
    case "facebook":
      pvdr = new FacebookAuthProvider();
      break;
    case "apple":
      pvdr = new OAuthProvider("apple.com");
      break;
  }
  try {
    return signInWithPopup(auth, pvdr);
  } catch {
    return null;
  }
};

export const signInWithCustomToken = async (token) => {
  const auth = getAuth(app);
  try {
    var user = await firebaseSignInWithCustomToken(auth, token);
  } catch (error) {
    console.log(error);
    return null;
  }
  return user;
};

const CUSTOM_TOKEN_ENDPOINT = `https://us-central1-hyfe-app.cloudfunctions.net/get_custom_auth_token`;

const getCustomToken = async (token: string) => {
  try {
    const response = await axios.get(CUSTOM_TOKEN_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return null;
  }
};

export enum SignInWithJWTStates {
  UNINIT = 0,
  GOT_CUSTOM_TOKEN = 1,
  SUCESS = 2,
  ERROR = -1,
  FAILED_GET_CUSTOM_TOKEN = -2,
  FAILED_LOGIN_CUSTOM_TOKEN = -3,
}

export const useSignInWithJWT = (
  token: string
): { status: SignInWithJWTStates } => {
  const [status, setStatus] = useState(SignInWithJWTStates.UNINIT);

  const [isTokenLogIn, setIsTokenLogIn] = useLocalStorage<boolean | null>(
    "isTokenLogIn",
    null
  );

  useEffect(() => {
    const do_the_thing = async () => {
      try {
        // 1. Contact get_custom_auth_token to get custom token
        const response = await getCustomToken(token);
        if (!response?.data?.custom_token) {
          setStatus(SignInWithJWTStates.FAILED_GET_CUSTOM_TOKEN);
          return;
        }
        setStatus(SignInWithJWTStates.GOT_CUSTOM_TOKEN);
        // 2. Use custom token to log in
        var uid = await signInWithCustomToken(response?.data?.custom_token);
        if (!uid) {
          setStatus(SignInWithJWTStates.FAILED_LOGIN_CUSTOM_TOKEN);
          return;
        } else {
          setStatus(SignInWithJWTStates.SUCESS);
        }
      } catch (error) {
        setStatus(SignInWithJWTStates.ERROR);
      }
    };
    if (token) {
      setIsTokenLogIn(true);
      do_the_thing();
    }
  }, [token]);

  return { status };
};

export const signOut = () => {
  const auth = getAuth(app);
  firebaseSignOut(auth)
    .then(() => {})
    .catch((error) => {
      console.log("Error while signing out: ", error.message);
    });
};

axios.interceptors.request.use(
  async (config) => {
    const token = await getAuth().currentUser?.getIdToken();
    if (config.headers && !config.headers.Authorization)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export * as InitFirestore from "firebase/firestore";
