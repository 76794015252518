import "./src/firebase/firebase";

import React from "react";

import AuthProvider from "./src/context/auth";
import ThemeProvider from "./src/context/theme";

export const wrapRootElement = ({ element, props }) => {
  return (
    <AuthProvider {...props}>
      <ThemeProvider>{element}</ThemeProvider>
    </AuthProvider>
  );
};
