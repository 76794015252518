import React, { createContext, useState, useEffect } from "react";

import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { createTheme, Theme } from "@mui/material";
import { jsx } from "@emotion/react";

export interface ThemeContextInterface {
  theme: Theme;
}

export const ThemeContext = createContext<ThemeContextInterface>(
  {} as ThemeContextInterface
);

const theme = createTheme();

const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
